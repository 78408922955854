import {createElement} from 'react';

export const convertNlToBr = (text: string) => {
  const regex = /(\n)/g;
  return text.split(regex).map((line, i) => {
    if (regex.test(line)) {
      return createElement('br', {key: i});
    }

    return line;
  });
};
