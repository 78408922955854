import {
  NotAuthorizedError,
  type ResultWithOauthToken,
  type AppError,
} from '@packages/utils';
import {
  cookieApiTokenKey,
  cookieApiRefreshTokenKey,
} from '../../../server/token';
import {
  SESSION_EXPIRED_RETURN_VALUE,
  handleRefreshAccessToken,
  type HandleRefreshAccessTokenArgs,
} from './handleRefreshAccessToken';
import {
  checkAccessTokenExistence,
  checkRefreshTokenExistence,
} from './checkOauthTokenExistence';

type SendApiRequestArgs<T> = HandleRefreshAccessTokenArgs<T> & {
  authRequired?: boolean;
  allowReturnError?: boolean;
};

// Cookieはクライアントサイドとサーバーサイドで取得方法が違うため、
// 呼び出し箇所で引数で呼び出す必要がある

export const sendApiRequest = async <T>({
  cookies,
  authRequired = true,
  request,
  version,
  allowReturnError = false,
}: SendApiRequestArgs<T>): Promise<ResultWithOauthToken<T, AppError>> => {
  const {isValid: isValidRefreshToken} = checkRefreshTokenExistence(
    cookies[cookieApiRefreshTokenKey],
  );
  const {isValid: isValidAccessToken, providedAccessToken} =
    checkAccessTokenExistence(cookies[cookieApiTokenKey]);

  // 未ログイン状態とアクセストークンが無効な場合が、同じ条件で判定になっている？
  if (authRequired && !isValidRefreshToken) {
    return SESSION_EXPIRED_RETURN_VALUE;
  }

  if (!isValidAccessToken && isValidRefreshToken) {
    return handleRefreshAccessToken({cookies, request, version});
  }

  const result = await request(providedAccessToken);
  if (!result.ok && result.error === NotAuthorizedError) {
    return handleRefreshAccessToken({cookies, request, version});
  }

  if (!result?.ok && !allowReturnError) {
    throw new Error(result.error);
  }

  return result;
};
