import {type FC, useState} from 'react';
import {
  FaTiktok,
  FaInstagram,
  FaYoutube,
  FaExternalLinkAlt,
} from 'react-icons/fa';
import {FaChevronRight, FaXTwitter} from 'react-icons/fa6';
import {useAuthAndCounts} from '../../../core/usecases/useAuthAndCounts';
import {
  LOGO_IMAGE_PATH_SQUARE_WHITE,
  APP_STORE_ANDROID_IMAGE,
  APP_STORE_IOS_IMAGE,
} from '../../../config/constants/img';
import {
  GSSKT_TWITTER_URL,
  GSSKT_YOUTUBE_URL,
  GSSKT_INSTAGRAM_URL,
  GSSKT_TIKTOK_URL,
} from '../../../config/constants/hosts';
import {
  searchCompaniesFromIndustryItems,
  searchReportsFromIndustryItems,
  searchReportsFromDepartmentItems,
  footerMenuItems,
  footerMenuItems2,
  gssktAppLink,
} from './items';

type Props = {
  className?: string;
};

type AccordionItemProps = {
  title: string;
  isOpen: boolean;
  onClick: () => void;
  items: Array<{href: string; label: string}>;
};

const AccordionItem: FC<AccordionItemProps> = ({
  title,
  isOpen,
  onClick,
  items,
}) => (
  <li className="px-8 mb-12">
    <div className="flex justify-between items-center" onClick={onClick}>
      <span>{title}</span>
      <FaChevronRight
        size={16}
        className={`transition-transform duration-200 ${isOpen ? 'rotate-90' : ''}`}
      />
    </div>
    <div
      className={`overflow-hidden ${
        isOpen
          ? 'transition-max-height duration-1000 ease-in-out max-h-screen'
          : 'transition-max-height duration-300 ease-in-out max-h-0'
      }`}
    >
      <ul className="ml-8">
        {items.map((item) => (
          <li key={item.label} className="my-8">
            <a href={item.href} className="block">
              {item.label}
            </a>
          </li>
        ))}
      </ul>
    </div>
  </li>
);

export const MobileFooter: FC<Props> = ({className}) => {
  const {user, isSignedIn} = useAuthAndCounts();
  const [isCompanyOpen, setIsCompanyOpen] = useState(false);
  const [isIndustryOpen, setIsIndustryOpen] = useState(false);
  const [departmentOpen, setDepartmentOpen] = useState(false);
  const handleCompanyClick = () => {
    setIsCompanyOpen(!isCompanyOpen);
  };

  const handleIndustryClick = () => {
    setIsIndustryOpen(!isIndustryOpen);
  };

  const handleDepartmentClick = () => {
    setDepartmentOpen(!departmentOpen);
  };

  return (
    <div className={className}>
      <div className="flex flex-col bg-background-footer px-16 pt-24 pb-[100px] text-white">
        <div className="flex flex-col text-base pb-24">
          <ul>
            {footerMenuItems.map((item) => (
              <li key={item.label} className="px-8 mb-12">
                <a
                  href={
                    // エンジニア学生にはデフォルト「エンジニア志望向け」にチェックが入っている状態で遷移させる
                    item.href === '/recruiting_info' && user?.isEngineer
                      ? `${item.href}?tagIDs=3`
                      : item.href
                  }
                  className="block"
                  target={item.target}
                >
                  {item.label}
                  {item.target && (
                    <span className="inline-flex">
                      <FaExternalLinkAlt />
                    </span>
                  )}
                </a>
              </li>
            ))}
            <AccordionItem
              title="企業を探す"
              isOpen={isCompanyOpen}
              onClick={handleCompanyClick}
              items={searchCompaniesFromIndustryItems}
            />
            <AccordionItem
              title="業界のES・体験記"
              isOpen={isIndustryOpen}
              onClick={handleIndustryClick}
              items={searchReportsFromIndustryItems}
            />
            <AccordionItem
              title="部門・職種別のES・体験記"
              isOpen={departmentOpen}
              onClick={handleDepartmentClick}
              items={searchReportsFromDepartmentItems}
            />
            {footerMenuItems2.map((item) => {
              const {label, ...itemProps} = item;

              return (
                <li key={item.label} className="px-8 mb-12">
                  <a {...itemProps}>{label}</a>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="flex flex-col items-start justify-between">
          <div className="flex justify-center items-center gap-12">
            <div className="pb-24">
              <img
                src={LOGO_IMAGE_PATH_SQUARE_WHITE}
                alt="ロゴ"
                className="w-full h-[60px]"
              />
            </div>
            <div className="flex justify-between gap-16">
              <a href={GSSKT_TWITTER_URL} target="_blank">
                <FaXTwitter size={24} />
              </a>
              <a href={GSSKT_INSTAGRAM_URL} target="_blank">
                <FaInstagram size={24} />
              </a>
              <a href={GSSKT_YOUTUBE_URL} target="_blank">
                <FaYoutube size={24} />
              </a>
              <a href={GSSKT_TIKTOK_URL} target="_blank">
                <FaTiktok size={24} />
              </a>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex max-w-[260px] gap-8 pb-12">
              <a href={gssktAppLink.ios}>
                <img src={APP_STORE_IOS_IMAGE} />
              </a>
              <a href={gssktAppLink.android}>
                <img src={APP_STORE_ANDROID_IMAGE} />
              </a>
            </div>
            <div className="text-sm text-white">
              © Howtelevision,Inc. All Rights Reserved.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
