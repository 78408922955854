import classNames from 'classnames';
import {type FC} from 'react';

type Props = {
  className?: string;
};

const items = [
  {id: 'read', href: '/company/reports', label: '読む'},
  {id: 'post', href: '/mypage/reports/add', label: '投稿する'},
];

export const HeaderReportMenu: FC<Props> = ({className}) => {
  return (
    <ul
      className={classNames(
        'bg-white text-black-1 text-sm divide-line flex flex-col divide-y py-4 px-12 ',
        className,
      )}
    >
      {items.map((item) => {
        const {id, href, label} = item;

        return (
          <li key={id || item.href}>
            <a href={href} className="flex justify-between p-8">
              <div>{label}</div>
            </a>
          </li>
        );
      })}
    </ul>
  );
};
