import {Drawer, Text} from '@mantine/core';
import classNames from 'classnames';
import Image from 'next/image';
import {useEffect, useState} from 'react';
import {MdMenu, MdClear, MdAccountCircle, MdOpenInNew} from 'react-icons/md';
import {useRouter} from 'next/router';
import {overlayConfig} from '../../modalConfig';
import {routes} from '../../../../config/constants';
import {useAuth} from '../../../../core/usecases/useAuth';
import {ENGINEER_LOGO_IMAGE_PATH_DEFAULT} from '../../../../config/constants/img';
import {useBooleanState} from '../../../services/useBooleanState';
import {HeaderMenu} from '../HeaderMenu';
import {COLOR_BLACK_1} from '../../../../config/constants/colorCodes';
import {
  ENGINEER,
  ENGINEER_COLUMN,
  ENGINEER_COMPANY,
  ENGINEER_RECRUITING_INFO,
} from '../../../../config/constants/routes';
import {QUERY_PARAMS_FOR_REDIRECT_FROM_LOGIN_PAGE} from '../../../../core/entities/login/constants';

type Props = {
  scoutsCount?: number;
  interestsCount?: number;
  className?: string;
};

const drawerList: Array<{
  name: string;
  url: string;
}> = [
  {
    name: 'スキルチェック',
    url: '/engineer/skill_check/top',
  },
  {
    name: 'AI選考対策',
    url: '/report_analytics/search',
  },
  {
    name: '募集を探す',
    url: '/recruiting_info',
  },
  {
    name: '企業を探す',
    url: '/company',
  },
  {
    name: 'ES・体験記',
    url: '/company/reports',
  },
  {
    name: '就活コラム',
    url: '/column/categories',
  },
  {
    name: 'コミュニティ',
    url: '/questions',
  },
  {
    name: '相談室',
    url: '/box/answers',
  },
  {
    name: '各種設定・その他',
    url: '/settings',
  },
];

const hamburgerList: Array<{
  name: string;
  url: string;
  showIcon: boolean;
}> = [
  {
    name: '企業をさがす',
    url: ENGINEER_COMPANY,
    showIcon: false,
  },
  {
    name: '募集をさがす',
    url: ENGINEER_RECRUITING_INFO,
    showIcon: false,
  },
  {
    name: 'ES・体験記',
    url: '/engineer/company/reports',
    showIcon: false,
  },
  {
    name: '就活コラム',
    url: ENGINEER_COLUMN,
    showIcon: false,
  },
  {
    name: 'イベント',
    url: `${ENGINEER}#events`,
    showIcon: false,
  },
  {
    name: 'AI選考対策',
    url: '/report_analytics/search',
    showIcon: true,
  },
  {
    name: '外資就活トップ',
    url: '/',
    showIcon: true,
  },
  {
    name: 'コミュニティ',
    url: '/questions',
    showIcon: true,
  },
  {
    name: '相談室',
    url: '/box/answers',
    showIcon: true,
  },
  {
    name: '各種設定・その他',
    url: '/settings',
    showIcon: true,
  },
];

const topPageHamburgerList: Array<{
  name: string;
  url: string;
  showIcon: boolean;
}> = [
  {
    name: '企業をさがす',
    url: '#companies',
    showIcon: false,
  },
  {
    name: '募集をさがす',
    url: '#recruiting_info',
    showIcon: false,
  },
  {
    name: 'ES・体験記',
    url: '#company_reports',
    showIcon: false,
  },
  {
    name: '就活コラム',
    url: '#columns',
    showIcon: false,
  },
  {
    name: 'イベント',
    url: '#events',
    showIcon: false,
  },
  {
    name: 'AI選考対策',
    url: '/report_analytics/search',
    showIcon: true,
  },
  {
    name: 'スキルチェック',
    url: '/engineer/skill_check/top',
    showIcon: true,
  },
  {
    name: '外資就活トップ',
    url: '/',
    showIcon: true,
  },
  {
    name: 'コミュニティ',
    url: '/questions',
    showIcon: true,
  },
  {
    name: '相談室',
    url: '/box/answers',
    showIcon: true,
  },
  {
    name: '各種設定・その他',
    url: '/settings',
    showIcon: true,
  },
];

export const EngineerMobileHeader: React.FC<Props> = ({className}) => {
  const {user, isSignedIn, isIdle, signOut} = useAuth();
  const [drawerOpened, , drawerClose, drawerToggle] = useBooleanState(false);
  const [hamburgerOpened, , hamburgerClose, hamburgerToggle] =
    useBooleanState(false);
  const [showSignInButton, setShowSignInButton] = useState(false);
  const router = useRouter();
  const hamburger =
    router.pathname === ENGINEER ? topPageHamburgerList : hamburgerList; // TOPページはアンカーリンク、それ以外は一覧ページに遷移

  useEffect(() => {
    if (!isSignedIn && !isIdle) setShowSignInButton(true);
  }, [isSignedIn, isIdle]);

  return showSignInButton ? (
    <div
      className={classNames(
        'bg-white flex p-16 items-center justify-between fixed w-full top-0 z-[100]',
        className,
      )}
    >
      <a
        href={ENGINEER}
        className="flex items-center pc:hover:opacity-80 pc:hover:duration-300 pc:hover:ease-in-out"
      >
        <Image
          src={ENGINEER_LOGO_IMAGE_PATH_DEFAULT}
          width="194"
          height="18"
          alt="外資就活ドットコム"
        />
      </a>

      <a
        href={`${routes.LOGIN}?${QUERY_PARAMS_FOR_REDIRECT_FROM_LOGIN_PAGE}=${router.asPath}`}
        className="text-sm font-bold text-primary"
      >
        ログイン・新規登録
      </a>
    </div>
  ) : (
    <div>
      <div
        className={classNames(
          'bg-white flex py-[14px] px-16 items-center justify-between fixed w-full top-0 z-[100]',
          className,
        )}
      >
        <div className="flex items-center">
          <button
            id="drawer-button"
            aria-label="Drawer Button"
            onClick={hamburgerToggle}
            className="pc:hover:opacity-80 pc:hover:duration-300 pc:hover:ease-in-out "
          >
            {hamburgerOpened ? (
              <MdClear size={24} fill={COLOR_BLACK_1} />
            ) : (
              <div className="relative">
                <MdMenu fill={COLOR_BLACK_1} size={24} />
              </div>
            )}
          </button>
        </div>
        <a
          href={ENGINEER}
          className="flex items-center pc:hover:opacity-80 pc:hover:duration-300 pc:hover:ease-in-out"
        >
          <Image
            src={ENGINEER_LOGO_IMAGE_PATH_DEFAULT}
            width="194"
            height="18"
            alt="外資就活ドットコム"
          />
        </a>
        <button className="flex items-center" onClick={drawerToggle}>
          <MdAccountCircle fill={COLOR_BLACK_1} size={20} />
        </button>
        <Drawer
          position="left"
          withCloseButton={false}
          opened={drawerOpened}
          onClose={() => {
            drawerClose();
          }}
          padding={0}
          size="70%"
          className="top-52"
          {...overlayConfig}
        >
          <div className="h-full bg-background-2 p-16">
            <div className="overflow-hidden rounded-12 bg-white-1 p-12">
              {user && (
                <div className="flex gap-8 px-12 pb-4 pt-12">
                  <MdAccountCircle size={24} className="text-black-1" />
                  <div className="font-bold text-black-1">
                    {user.familyName}さん
                  </div>
                </div>
              )}
              <HeaderMenu
                showMyPage={isSignedIn}
                onLogout={signOut}
                className="w-full bg-white-1 text-black-1"
              />
            </div>
            <ul className="mt-12 flex flex-col divide-y divide-line">
              {drawerList.map((item) => {
                return (
                  <li key={item.name} className="p-12">
                    <a
                      href={
                        // エンジニア学生にはデフォルト「エンジニア志望向け」にチェックが入っている状態で遷移させる
                        item.url === '/recruiting_info' && user?.isEngineer
                          ? `${item.url}?label_ids=3`
                          : item.url
                      }
                      className="flex items-center font-bold leading text-black-1"
                    >
                      <span className="ml-8">{item.name}</span>
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </Drawer>
        {hamburgerOpened && (
          <div className="absolute top-48 left-16 bg-white shadow-modal p-12 flex flex-col z-10 rounded-12">
            {hamburger.map((item) => (
              <button
                onClick={() => {
                  if (item.showIcon) {
                    window.open(item.url);
                  } else {
                    window.location.href = item.url;
                  }
                }}
                className="w-[160px] p-8 border-b-[1px] text-base text-left justify-between flex items-center"
              >
                <Text>{item.name}</Text>
                {item.showIcon && <MdOpenInNew />}
              </button>
            ))}
          </div>
        )}
      </div>
      <div className="bg-white-1 flex py-12 px-16 items-center justify-between text-[13px] font-bold text-black-1 border-t-[1px] mt-[52px]">
        <Text className="m-auto">
          エンジニアとして 理想のファーストキャリアを 目指そう
        </Text>
      </div>
    </div>
  );
};
