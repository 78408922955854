export const FAVICON_PATH = '/next-image/common/favicon.ico';
export const OGP_IMAGE_PATH_DEFAULT = '/next-image/common/ogp.png';
export const ADVISER_PATH_DEFAULT = '/next-image/box/default.svg';
export const LINE_LINKAGE_BANNER = '/next-image/scout/line_linkage_banner.png';

export const NEW_LOGO_IMAGE_PATH_APP_ICON =
  '/next-image/common/logo/app-icon.svg';

export const LOGO_IMAGE_PATH_DEFAULT = '/next-image/common/logo/default.svg';
export const LOGO_IMAGE_PATH_FULL = '/next-image/common/logo/full.svg';
export const LOGO_IMAGE_PATH_SQUARE = '/next-image/common/logo/square.svg';
export const LOGO_IMAGE_PATH_SQUARE_WHITE =
  '/next-image/common/logo/square_white.svg';

export const ENGINEER_LOGO_IMAGE_PATH_DEFAULT =
  '/next-image/common/engineerLogo/default.svg';
export const ENGINEER_LOGO_IMAGE_PATH_PICTGRAM =
  '/next-image/common/engineerLogo/pictgram.svg';
export const ENGINEER_LOGO_IMAGE_PATH_OGP =
  '/next-image/common/engineerLogo/ogp.png';

export const COMPANY_FOLLOW_PC = '/next-image/company/company_follow-pc.png';
export const COMPANY_FOLLOW_SP = '/next-image/company/company_follow-sp.png';

export const GMAIL_PC_IMAGE = '/next-image/gmail/pc_image.jpg';
export const GMAIL_PC_MBOX = '/next-image/gmail/pc_mbox.jpg';
export const GMAIL_SP_1 = '/next-image/gmail/sp1.jpg';
export const GMAIL_SP_2 = '/next-image/gmail/sp2.jpg';
export const GMAIL_SP_3 = '/next-image/gmail/sp3.jpg';

export const SCOUT_FLOW_IMAGE_1 = '/next-image/scout/flow1.png';
export const SCOUT_FLOW_IMAGE_2 = '/next-image/scout/flow2.png';
export const SCOUT_FLOW_IMAGE_3 = '/next-image/scout/flow3.png';
export const SCOUT_FLOW_IMAGE_4 = '/next-image/scout/flow4.png';
export const SCOUT_IMAGE_1 = '/next-image/scout/scout1.png';

export const BANNER_EVENT_IMAGE = '/next-image/box/banner/banner_event.svg';
export const CALENDAR_BUTTON_IMAGE =
  '/next-image/box/banner/calendar_button.png';
export const LP_COLUMN_1 = '/next-image/lp/column1.png';
export const LP_COLUMN_2 = '/next-image/lp/column2.png';
export const LP_COLUMN_3 = '/next-image/lp/column3.png';
export const LP_EVENT_1 = '/next-image/lp/event1.png';
export const LP_GSSKT_LOGO = '/next-image/lp/pic_gsskt.png';
export const LP_LIIGA_LOGO = '/next-image/lp/pic_liiga.png';
export const LP_CHECK_ICON = '/next-image/lp/check.svg';
export const LP_SHOWER_PC = '/next-image/lp/pic_shower_pc.svg';
export const LP_SHOWER_SP = '/next-image/lp/pic_shower_sp.svg';
export const ENGINEER_FAVICON_PATH = '/next-image/engineer/favicons';
export const ENGINEER_OGP_IMAGE = '/next-image/engineer/gsskt_eng_OGP.png';
export const ENGINEER_PC_IMAGE = '/next-image/engineer/gsskt_eng_kv.png';
export const ENGINEER_IMAGE_1 = '/next-image/engineer/engineer1.png';
export const ENGINEER_IMAGE_2 = '/next-image/engineer/engineer2.png';
export const ENGINEER_IMAGE_3 = '/next-image/engineer/engineer3.png';
export const ENGINEER_GUILD_LOGO = '/next-image/engineer/guild_logo.png';
export const ENGINEER_ARCHIVE_COMPANY_LOGO =
  '/next-image/engineer/archive_company_logo.png';
export const ENGINEER_BANNER_TO_EDIT_PROFILE_PC =
  '/next-image/engineer/banner_to_profile_edit_pc.png';

export const ENGINEER_BANNER_TO_EDIT_PROFILE_SP =
  '/next-image/engineer/banner_to_profile_edit_sp.png';

export const ENGINEER_EVENT_IMAGE1 = '/next-image/engineer/event_image1.png';
export const ENGINEER_EVENT_IMAGE2 = '/next-image/engineer/event_image2.png';
export const ENGINEER_EVENT_IMAGE3 = '/next-image/engineer/event_image3.png';
export const ENGINEER_EVENT_IMAGE4 = '/next-image/engineer/event_image4.png';
export const ENGINEER_EVENT_HACKATHON2411_IMAGE =
  '/next-image/engineer/event_hackathon2411.png';

export const ENGINEER_COLUMN_DEFAULT_IMAGE =
  '/next-image/engineer/column_default.png';

export const REGISTER_IMAGE_1 = '/next-image/register/recruitment.png';
export const REGISTER_IMAGE_2 = '/next-image/register/community.png';
export const REGISTER_IMAGE_3 = '/next-image/register/column.png';
export const REGISTER_IMAGE_4 = '/next-image/register/selection-report.png';

export const REPORT_ANALYTICS_LOGO = '/next-image/reportAnalytics/logo.png';
export const REPORT_ANALYTICS_BACKGROUND =
  '/next-image/reportAnalytics/background.png';
export const REPORT_ANALYTICS_ABOUT_1 =
  '/next-image/reportAnalytics/about1.png';
export const REPORT_ANALYTICS_ABOUT_2 =
  '/next-image/reportAnalytics/about2.png';
export const REPORT_ANALYTICS_ABOUT_3 =
  '/next-image/reportAnalytics/about3.png';
export const REPORT_ANALYTICS_HEAD_IMAGE =
  '/next-image/reportAnalytics/head-image.png';
export const REPORT_ANALYTICS_OGP_IMAGE =
  '/next-image/reportAnalytics/OGP_1200x630.png';
export const REPORT_ANALYTICS_BUTTON_DEFAULT =
  '/next-image/reportAnalytics/ai-summary-button-deafault.png';
export const REPORT_ANALYTICS_MAIN_BUTTON =
  '/next-image/reportAnalytics/main-button.png';
export const REPORT_ANALYTICS_MAIN_BANNER =
  '/next-image/reportAnalytics/main-banner.png';
export const LINE_NOTIFICATION_BANNER_IMAGE =
  '/next-image/scout/line_notification_right_sidebar.png';

export const REGISTER_COMMUNITY_BENEFIT_IMAGE_PATH =
  '/next-image/register/community_benefit.png';
export const REGISTER_COMPANY_BENEFIT_IMAGE_PATH =
  '/next-image/register/company_benefit.png';
export const REGISTER_SCOUT_BENEFIT_IMAGE_PATH =
  '/next-image/register/scout_benefit.png';

export const APP_DL_BANNER_PC = '/next-image/register/app_dl_banner_pc.jpeg';
export const APP_DL_BANNER_SP = '/next-image/register/app_dl_banner.png';

export const WEB_SEMINARS_IMAGE_1 =
  '/next-image/web_seminars/func_detail_1.png';
export const WEB_SEMINARS_IMAGE_2 =
  '/next-image/web_seminars/func_detail_3.png';

export const KAIGAIDAI_BANNER = '/next-image/top/kaigaidai.png';

export const APP_STORE_ANDROID_IMAGE =
  '/next-image/common/appStore/android.png';
export const APP_STORE_IOS_IMAGE = '/next-image/common/appStore/ios.png';

export const TIEUP_RIKUNABI_2026 = '/next-image/tieup/rikunabi_2026_logo.png';
export const TIEUP_TYPESHUKATSU =
  '/next-image/tieup/type_shukatsu_2025_logo.png';
export const SIGN_IN_WITH_GOOGLE =
  '/next-image/common/sns/SignInWithGoogle.svg';

export const SKILL_CHECK_MAIN_VISUAL_PC =
  '/next-image/skillCheck/main_visual_pc.png';
export const SKILL_CHECK_MAIN_VISUAL_SP =
  '/next-image/skillCheck/main_visual_sp.png';
export const SKILL_CHECK_LOGO = '/next-image/skillCheck/logo.png';
export const SKILL_CHECK_OGP = '/next-image/skillCheck/ogp.png';
export const SKILL_CHECK_AWS = '/next-image/skillCheck/aws.png';
export const SKILL_CHECK_GITHUB = '/next-image/skillCheck/github.png';
export const SKILL_CHECK_GOLANG = '/next-image/skillCheck/golang.png';
export const SKILL_CHECK_NODE = '/next-image/skillCheck/node.js.png';
export const SKILL_CHECK_PYTHON = '/next-image/skillCheck/python.png';
export const SKILL_CHECK_REACT = '/next-image/skillCheck/react.png';
export const SKILL_CHECK_SQL = '/next-image/skillCheck/sql.png';
export const SKILL_CHECK_TYPESCRIPT = '/next-image/skillCheck/typescript.png';
