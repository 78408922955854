export const sendOauthTokenToRNApp = ({
  accessToken,
  refreshToken,
}: {
  accessToken: string;
  refreshToken: string;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  (window as any).ReactNativeWebView.postMessage(
    JSON.stringify({accessToken, refreshToken}),
  );
};
