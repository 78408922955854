import {useCallback, useState} from 'react';
import {useRouter} from 'next/router';
import {
  type UsersMeResponse,
  useUsersMe,
} from '../repositories/usersMeRepository';
import {extractAccessTokenFromCookie} from '../../shared/services/extractAccessTokenFromCookie';
import {HOME} from '../../config/constants/routes';
import {logoutFromCakePHP} from '../repositories/sessionRepository';
import {
  checkAccessTokenExistence,
  checkRefreshTokenExistence,
} from './auth/checkOauthTokenExistence';
import {extractRefreshTokenFromCookie} from './auth/extractOauthTokenFromCookie';
import {destroyCookieWithLogout} from './auth/destroyCookieWithLogout';

export type UserType = UsersMeResponse;

export const useAuth = () => {
  const accessToken = extractAccessTokenFromCookie();
  const refreshToken = extractRefreshTokenFromCookie();
  const {providedAccessToken} = checkAccessTokenExistence(accessToken);
  const {isValid} = checkRefreshTokenExistence(refreshToken);

  const [user, setUser] = useState<UserType | undefined>(undefined);
  const [isIdle, setIsIdle] = useState(isValid);
  const router = useRouter();

  useUsersMe({
    config: {
      enabled: isValid,
      onSuccess(data) {
        if (data.ok) {
          setUser(data.value);
          setIsIdle(false);
        }
      },
      onError() {
        setIsIdle(false);
      },
    },
  });

  const signOut = useCallback(async () => {
    await logoutFromCakePHP();
    destroyCookieWithLogout();

    if (router.pathname === HOME) {
      router.reload(); // トップページでログアウトした場合表示が更新されないためreloadする
      return;
    }

    await router.push(HOME);
  }, []);

  return {
    user,
    accessToken: providedAccessToken,
    signOut,
    isIdle,
    isSignedIn: !isIdle && Boolean(user),
  };
};
