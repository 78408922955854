type Hosts = Record<string, string>;

export const LIIGA_HOST: Hosts = {
  production: 'https://liiga.me',
  staging: 'https://dev.liiga.me',
  sirloin: 'https://dev.liiga.me',
  tongue: 'https://dev.liiga.me',
  development: 'https://dev.liiga.me',
};

export const GSSKT_TWITTER_URL = 'https://twitter.com/gaishishukatsu';

export const GSSKT_FACEBOOK_URL = 'https://www.facebook.com/gaishishukatsu';

export const GSSKT_YOUTUBE_URL =
  'https://www.youtube.com/channel/UC9_2jJe84pFJbufV_hQ9-Pg';

export const GSSKT_INSTAGRAM_URL = 'https://www.instagram.com/gaishishukatsu/';

export const GSSKT_TIKTOK_URL = 'https://www.tiktok.com/@gaishishukatsu_com';
