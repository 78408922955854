import {useRouter} from 'next/router';
import {routes} from '../../config/constants';

/**
 * メール認証ページへの遷移元パスをクエリパラメータに追加
 */
export const getMailAuthLinkWithParamsHooks = (
  parameters: Record<string, string>,
) => {
  const router = useRouter();
  const paramsWithInflowPath: Record<string, string> = {
    ...parameters,
    inflowPath: router.asPath.replace(/\?.*$/, ''),
  };
  return `${routes.MAIL_AUTH}?${new URLSearchParams(paramsWithInflowPath).toString()}`;
};
