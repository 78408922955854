import {
  InvalidTokenError,
  type ResultWithOauthToken,
  type AppError,
  type Result,
} from '@packages/utils';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  type UseMutationResult,
  type UseQueryOptions,
} from '@tanstack/react-query';
import type * as z from 'zod';
import {parseCookies} from 'nookies';
import {DEFAULT_API_VERSION} from '../../config/apiVersion';
import {defaultClient} from '../../lib/defaultClient';
import {type MutationConfig} from '../../lib/react-query';
import {createPathWithQuery} from '../../shared/services/createPathWithQuery';
import {
  ScoutSort,
  type MessageTypesEnum,
  type ScoutSortEnum,
  type ScoutStatusEnum,
  type UserTypesEnum,
} from '../entities/scouts/constants';
import {
  GetChatMessagesResponse,
  GetScoutResponse,
  GetScoutsResponse,
  GetUnnotifiedResponse,
  GetUnreadScoutMessagesCountResponse,
  PostChatMessagesResponse,
  PutNotifiedLogsResponse,
  ScoutMessageStatusResponse,
  type ChatMessage,
  type ChatMessageList,
  type PutNotifiedLogsData,
  type Scout,
  type ScoutList,
} from '../entities/scouts/schema';
import {sendApiRequest} from '../usecases/auth/sendApiRequest';
import {useIsSessionExpired} from '../usecases/auth/useIsSessionExpired';

// GetScout
export type ScoutStatusType = z.infer<typeof ScoutStatusEnum>;

export type ScoutType = z.infer<typeof Scout>;

export type GetScoutResponseType = z.infer<typeof GetScoutResponse>;

type ReturnScoutValueType = Result<GetScoutResponseType, AppError>;

type GetScoutOptions = {
  cookies: Partial<Record<string, string>>;
  scoutID: number;
  version?: string;
};

// GetScouts
export type ScoutListType = z.infer<typeof ScoutList>;

export type ScoutSortType = z.infer<typeof ScoutSortEnum>;

export type GetScoutsResponseType = z.infer<typeof GetScoutsResponse>;

type ReturnScoutsValueType = Result<GetScoutsResponseType, AppError>;

type GetScoutsArgs = {
  cookies: Partial<Record<string, string>>;
  status?: ScoutStatusType[];
  page?: number;
  sort?: ScoutSortType;
  version?: string;
};

type UseGetScoutsOptions = {
  config?: UseQueryOptions<ReturnScoutsValueType>;
} & GetScoutsArgs;

// GetChatMessage

export type UserTypesType = z.infer<typeof UserTypesEnum>;

export type MessageTypesType = z.infer<typeof MessageTypesEnum>;

export type ChatMessageType = z.infer<typeof ChatMessage>;
export type ChatMessageListType = z.infer<typeof ChatMessageList>;

export type ChatMessagesType = z.infer<typeof GetChatMessagesResponse>;

type ReturnChatMessageValueType = Result<ChatMessagesType, AppError>;

type GetChatMessagesArgs = {
  cookies: Partial<Record<string, string>>;
  scoutID: number;
  page?: number;
  limit?: number;
  status?: ScoutStatusType;
  version?: string;
};

export type PostChatMessagesResponseType = z.infer<
  typeof PostChatMessagesResponse
>;

type ReturnPostChatMessagesValueType = Result<
  PostChatMessagesResponseType,
  AppError
>;

type PostChatMessageArgs = {
  cookies: Partial<Record<string, string>>;
  scoutID: number;
  messageType: MessageTypesType;
  body: string;
  version?: string;
};

// PutScoutMessage
export type ScoutMessageStatusResponse = z.infer<
  typeof ScoutMessageStatusResponse
>;

type ReturnPutScoutMessageValueType = Result<
  ScoutMessageStatusResponse,
  AppError
>;

type PutScoutMessageStatusArgs = {
  cookies: Partial<Record<string, string>>;
  scoutID: number;
  status: ScoutStatusType;
  version?: string;
};

type UsePutScoutMessageStatusOptions = {
  config?: MutationConfig<typeof putScoutMessageStatus>;
} & PutScoutMessageStatusArgs;

export type GetUnreadScoutMessagesCountResponse = z.infer<
  typeof GetUnreadScoutMessagesCountResponse
>;

export type GetUnreadScoutMessagesCountArg = {
  cookies: Partial<Record<string, string>>;
  version?: string;
  args?: Record<string, string>;
};

// GetScout
export const getScout = async ({
  scoutID,
  cookies,
  version = DEFAULT_API_VERSION,
}: GetScoutOptions) => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    return defaultClient.get(
      `/${version}/users/me/scout-messages/${scoutID}`,
      GetScoutResponse,
    );
  };

  const result = await sendApiRequest({
    request,
    cookies,
    version,
    allowReturnError: true,
  });

  return result;
};

export const useGetScout = ({
  scoutID,
  version = DEFAULT_API_VERSION,
}: Omit<GetScoutOptions, 'cookies'>) => {
  const {updateIsSessionExpired} = useIsSessionExpired();
  const {data, isLoading} = useQuery<ReturnScoutValueType>(
    ['useGetScout', scoutID],
    {
      async queryFn() {
        const cookies = parseCookies();
        const result = await getScout({scoutID, cookies, version});
        if (!result.ok && result.error === InvalidTokenError) {
          updateIsSessionExpired(true);
        }

        return result;
      },
      enabled: scoutID !== 0,
    },
  );
  return {
    data,
    isLoading,
  };
};

// GetScouts
export const getScouts = async ({
  cookies,
  status,
  sort = ScoutSort.new,
  page,
  version = DEFAULT_API_VERSION,
}: GetScoutsArgs) => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    let parameters: {
      status?: ScoutStatusType[];
      sort?: ScoutSortType;
      page?: number;
    } = {
      sort,
    };
    if (status !== undefined) {
      parameters = {...parameters, page, status};
    }

    const path = createPathWithQuery(
      `/${version}/users/me/scout-messages`,
      parameters,
    );
    return defaultClient.get(path, GetScoutsResponse);
  };

  const result = await sendApiRequest({
    request,
    cookies,
    version,
  });
  return result;
};

export const useInfiniteScouts = ({
  status,
  sort = ScoutSort.new,
  version = DEFAULT_API_VERSION,
}: Omit<UseGetScoutsOptions, 'cookies'>) => {
  const {updateIsSessionExpired} = useIsSessionExpired();
  return useInfiniteQuery(
    ['scouts', status, sort],
    async ({pageParam: pageParameter = 1}) => {
      const cookies = parseCookies();
      const result = await getScouts({
        cookies,
        status,
        page: pageParameter,
        sort,
        version,
      });
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      return result;
    },
    {
      getPreviousPageParam: () => 1,
      getNextPageParam: (lastPage) =>
        lastPage.ok && lastPage.value.nextPage > 0
          ? lastPage.value.nextPage
          : undefined,
    },
  );
};

// GetChatMessages
export const getChatMessages = async ({
  cookies,
  scoutID,
  page = 1,
  limit = 20,
  version = DEFAULT_API_VERSION,
}: GetChatMessagesArgs): Promise<ReturnChatMessageValueType> => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    const path = createPathWithQuery(
      `/${version}/chat-rooms/scouts/${scoutID}/messages`,
      {
        page,
        limit,
      },
    );
    return defaultClient.get(path, GetChatMessagesResponse);
  };

  const result = await sendApiRequest({
    request,
    cookies,
    version,
  });

  return result;
};

// PostChatMessage
export const postChatMessage = async ({
  cookies,
  scoutID,
  messageType,
  body,
  version = DEFAULT_API_VERSION,
}: PostChatMessageArgs): Promise<ReturnPostChatMessagesValueType> => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    const path = createPathWithQuery(
      `/${version}/chat-rooms/scouts/${scoutID}/messages`,
      {},
    );
    return defaultClient.post(path, PostChatMessagesResponse, {
      messageType,
      body,
    });
  };

  const result = await sendApiRequest({
    request,
    cookies,
    version,
  });

  return result;
};

// PutScoutMessage
export const putScoutMessageStatus = async ({
  cookies,
  scoutID,
  status,
  version = DEFAULT_API_VERSION,
}: PutScoutMessageStatusArgs): Promise<ReturnPutScoutMessageValueType> => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    return defaultClient.put(
      `/${version}/users/me/scout-messages/${scoutID}/status`,
      ScoutMessageStatusResponse,
      {status},
    );
  };

  const result = await sendApiRequest({
    request,
    cookies,
    version,
  });
  return result;
};

export const usePutScoutMessageStatus = ({
  scoutID,
  status,
  version = DEFAULT_API_VERSION,
}: UsePutScoutMessageStatusOptions) => {
  const {updateIsSessionExpired} = useIsSessionExpired();
  return useMutation<ReturnPutScoutMessageValueType>(async () => {
    const cookies = parseCookies();
    const result = await putScoutMessageStatus({
      cookies,
      scoutID,
      status,
      version,
    });
    if (!result.ok && result.error === InvalidTokenError) {
      updateIsSessionExpired(true);
    }

    return result;
  });
};

export type GetUnreadScoutMessagesCountResponseType = z.infer<
  typeof GetUnreadScoutMessagesCountResponse
>;

// GetUnreadScoutMessage
export const getUnreadScoutMessagesCount = async ({
  cookies,
  version = DEFAULT_API_VERSION,
  args,
}: GetUnreadScoutMessagesCountArg) => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    const path = `/${version}/chat-rooms/scouts/unread-messages`;
    const pathWithQuery = createPathWithQuery(path, {...args});
    return defaultClient.get(
      pathWithQuery,
      GetUnreadScoutMessagesCountResponse,
    );
  };

  const result = await sendApiRequest({
    request,
    cookies,
    version,
  });
  return result;
};

// GetUnnotifieds
type GetUnnotifiedArgs = {
  version?: string;
  cookies: Partial<Record<string, string>>;
};

export type GetUnnotifiedSchema = z.infer<typeof GetUnnotifiedResponse>;
type ResultGetUnnotifiedSchema = ResultWithOauthToken<
  GetUnnotifiedSchema,
  AppError
>;

type UseGetUnnotifiedOptions = {
  config?: UseQueryOptions<ResultGetUnnotifiedSchema>;
} & Omit<GetUnnotifiedArgs, 'cookies'>;

export const useGetUnnotified = ({
  config,
  version,
}: UseGetUnnotifiedOptions) => {
  const {updateIsSessionExpired} = useIsSessionExpired();
  return useQuery<ResultGetUnnotifiedSchema>({
    ...config,
    queryKey: ['useGetUnnotified'],
    async queryFn() {
      const cookies = parseCookies();
      const result = await getUnnotified({cookies, version});
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      return result;
    },
  });
};

export const getUnnotified = async ({
  version = DEFAULT_API_VERSION,
  cookies,
}: {
  cookies: Partial<Record<string, string>>;
  version?: string;
}) => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    return defaultClient.get(
      `/${version}/users/me/scout-messages/unnotified`,
      GetUnnotifiedResponse,
    );
  };

  const result = await sendApiRequest({
    request,
    cookies,
    version,
  });
  return result;
};

// PutNotifiedLogs
type putNotifiedLogsType = {
  onSuccessFnc: () => void;
  onErrorFnc: (error: unknown) => void;
};

type ReturnPutValueType = Result<PutNotifiedLogsResponse, AppError>;

export type PutNotifiedLogsDataType =
  | z.infer<typeof PutNotifiedLogsData>
  | unknown;

export type PutNotifiedLogsResponse = z.infer<typeof PutNotifiedLogsResponse>;
export type ResultPutNotifiedLogsResponse = Result<
  PutNotifiedLogsResponse,
  AppError
>;

type PutNotifiedLogsArgs = {
  cookies: Partial<Record<string, string>>;
  data: PutNotifiedLogsDataType;
  version?: string;
};

export const usePutNotifiedLogs = ({
  onSuccessFnc,
  onErrorFnc,
}: putNotifiedLogsType): UseMutationResult<ReturnPutValueType, AppError> => {
  const {updateIsSessionExpired} = useIsSessionExpired();
  return useMutation(
    async (data) => {
      const cookies = parseCookies();
      const result = await putNotifiedLogs({cookies, data});
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      return result;
    },
    {
      onSuccess() {
        onSuccessFnc();
      },
      onError(error) {
        onErrorFnc(error);
      },
    },
  );
};

export const putNotifiedLogs = async ({
  cookies,
  data,
  version = DEFAULT_API_VERSION,
}: PutNotifiedLogsArgs) => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    return defaultClient.put(
      `/${version}/users/me/scout-messages/notified-logs`,
      PutNotifiedLogsResponse,
      JSON.stringify(data),
    );
  };

  const result = await sendApiRequest({
    request,
    cookies,
    version,
  });

  return result;
};
