import type {FC} from 'react';
import {
  FaTiktok,
  FaInstagram,
  FaYoutube,
  FaExternalLinkAlt,
} from 'react-icons/fa';
import {FaXTwitter} from 'react-icons/fa6';
import {useAuthAndCounts} from '../../../core/usecases/useAuthAndCounts';
import {
  LOGO_IMAGE_PATH_SQUARE_WHITE,
  APP_STORE_ANDROID_IMAGE,
  APP_STORE_IOS_IMAGE,
} from '../../../config/constants/img';
import {
  GSSKT_TWITTER_URL,
  GSSKT_YOUTUBE_URL,
  GSSKT_INSTAGRAM_URL,
  GSSKT_TIKTOK_URL,
} from '../../../config/constants/hosts';
import {
  searchCompaniesFromIndustryItems,
  searchReportsFromIndustryItems,
  searchReportsFromDepartmentItems,
  footerMenuItems,
  footerMenuItems2,
  gssktAppLink,
} from './items';

type Props = {
  className?: string;
};

export const PcFooter: FC<Props> = ({className}) => {
  const {user, isSignedIn} = useAuthAndCounts();

  return (
    <div className={className}>
      <div className="h-[508px] flex justify-center items-start gap-24 p-64 bg-background-footer">
        <div className="flex-1 flex flex-col h-full items-start justify-between">
          <div className="flex flex-col gap-12">
            <div className="pb-8">
              <img
                src={LOGO_IMAGE_PATH_SQUARE_WHITE}
                alt="ロゴ"
                className="w-full h-[100px]"
              />
            </div>
            <div className="flex justify-between text-white">
              <a href={GSSKT_TWITTER_URL} target="_blank">
                <FaXTwitter />
              </a>
              <a href={GSSKT_INSTAGRAM_URL} target="_blank">
                <FaInstagram />
              </a>
              <a href={GSSKT_YOUTUBE_URL} target="_blank">
                <FaYoutube />
              </a>
              <a href={GSSKT_TIKTOK_URL} target="_blank">
                <FaTiktok />
              </a>
            </div>
          </div>
          <div className="flex flex-col gap-8">
            <div className="flex w-full gap-8">
              <a href={gssktAppLink.ios}>
                <img src={APP_STORE_IOS_IMAGE} />
              </a>
              <a href={gssktAppLink.android}>
                <img src={APP_STORE_ANDROID_IMAGE} />
              </a>
            </div>
            <div className="text-xs text-white">
              © Howtelevision,Inc. All Rights Reserved.
            </div>
          </div>
        </div>
        <div className="flex-1 flex h-full items-start">
          <ul className="flex flex-col text-sm text-white font-bold gap-8">
            {footerMenuItems.map((item) => (
              <li key={item.label}>
                <a
                  href={
                    // エンジニア学生にはデフォルト「エンジニア志望向け」にチェックが入っている状態で遷移させる
                    item.href === '/recruiting_info' && user?.isEngineer
                      ? `${item.href}?tagIDs=3`
                      : item.href
                  }
                  className="block"
                  target={item.target}
                >
                  {`${item.label} `}
                  {item.target && (
                    <span className="inline-flex">
                      <FaExternalLinkAlt />
                    </span>
                  )}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex-1 flex flex-col h-full items-start">
          <div className="text-white">
            <div className="font-bold text-sm pb-8">企業を探す</div>
            <ul className="flex flex-col gap-8 text-sm">
              {searchCompaniesFromIndustryItems.map((item) => (
                <li key={item.label}>
                  <a href={item.href}>{item.label}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="flex-1 flex flex-col h-full items-start">
          <div className="text-white">
            <div className="font-bold text-sm pb-8">業界のES・体験記</div>
            <ul className="flex flex-col gap-8 text-sm">
              {searchReportsFromIndustryItems.map((item) => (
                <li key={item.label}>
                  <a href={item.href}>{item.label}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="flex-1 flex flex-col h-full items-start">
          <div className="text-white">
            <div className="font-bold text-sm pb-8">
              部門・職種別のES・体験記
            </div>
            <ul className="flex flex-col gap-8 text-sm">
              {searchReportsFromDepartmentItems.map((item) => (
                <li key={item.label}>
                  <a href={item.href}>{item.label}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="flex-1 flex flex-col h-full items-start">
          <div className="text-white">
            <ul className="flex flex-col gap-8 text-sm">
              {footerMenuItems2.map((item) => {
                const {label, ...itemProps} = item;

                return (
                  <li key={item.label} className="px-8">
                    <a {...itemProps}>{label}</a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
