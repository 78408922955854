import type {FC} from 'react';
import {useRouter} from 'next/router';
import {checkPathForPopup} from '../services/checkPathForPopup';
import {useMediaQuery} from '../services/useMediaQuery';
import {EngineerMobileHeader} from './Header/Engineer/EngineerMobileHeader';
import {EngineerPcHeader} from './Header/Engineer/EngineerPcHeader';
import {GeneralHeader} from './Header/GeneralHeader';
import {Popup} from './Header/Popup';

type Props = {
  className?: string;
};

export const Header: FC<Props> = ({className}) => {
  const router = useRouter();
  const {isPC} = useMediaQuery();
  const isEngineerPage = router.pathname.includes('engineer');

  return (
    <div className={className}>
      <div className="pc:hidden">
        {isEngineerPage ? (
          <EngineerMobileHeader />
        ) : (
          <GeneralHeader isPC={isPC} />
        )}
      </div>
      <div className="hidden pc:block">
        {isEngineerPage ? <EngineerPcHeader /> : <GeneralHeader isPC={isPC} />}
      </div>
      {checkPathForPopup() && <Popup />}
    </div>
  );
};
