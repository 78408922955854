import {Box} from '@mantine/core';
import {useEffect, type FC} from 'react';
import {useRouter} from 'next/router';
import {routes} from '../../../config/constants';
import isAppWebView from '../../../config/isAppWebView';
import {redirectToLoginOnSessionExpiration} from '../../../core/usecases/auth/redirectToLoginOnTokenExpiraration';
import {CommonLink} from '../CommonLink';
import {Header} from '../Header';
import {Footer} from '../Footer';
import {QUERY_PARAMS_FOR_REDIRECT_FROM_LOGIN_PAGE} from '../../../core/entities/login/constants';
import {destroyCookieWithLogout} from '../../../core/usecases/auth/destroyCookieWithLogout';

export const SessionExpiredPage: FC<{resolvedUrl: string}> = ({
  resolvedUrl,
}) => {
  const router = useRouter();

  useEffect(() => {
    destroyCookieWithLogout();

    if (
      isAppWebView() &&
      typeof window !== 'undefined' &&
      (window as any).ReactNativeWebView
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      (window as any).ReactNativeWebView.postMessage(
        JSON.stringify({redirectToLoginOnSessionExpiration: true}),
      );
    } else {
      setTimeout(async () => {
        await redirectToLoginOnSessionExpiration(resolvedUrl);
      }, 1500);
    }
  }, []);

  return (
    <>
      <div>
        {!isAppWebView() && <Header />}
        <main>
          <Box className="flex pc:min-h-[500px] min-h-[300px] items-center justify-center bg-background-1 py-12 pc:py-32">
            <div className="bg-white-1 flex flex-col items-center justify-center p-36 w-[90%] max-w-[600px] shadow-bottom">
              <p className="text-md leading">セッションが切れました</p>
              <p className="text-md leading">再ログインをお願いします</p>
              <CommonLink
                href={`${routes.LOGIN}?${QUERY_PARAMS_FOR_REDIRECT_FROM_LOGIN_PAGE}=${router.asPath}`}
                className="text-link text-md leading mt-12 pc:hover:opacity-80"
              >
                ログイン画面へ
              </CommonLink>
            </div>
          </Box>
        </main>
        {!isAppWebView() && <Footer />}
      </div>
    </>
  );
};
