import {
  cookieApiRefreshTokenKey,
  cookieApiTokenKey,
} from '../../../server/token';

type AccessTokenCheckResult = {
  isValid: boolean;
  providedAccessToken: string;
};
// MEMO: アクセストークンの有無をチェック
export function checkAccessTokenExistence(
  accessToken: string | undefined,
): AccessTokenCheckResult {
  if (typeof accessToken !== 'string')
    return {isValid: false, providedAccessToken: ''};
  return {isValid: Boolean(accessToken), providedAccessToken: accessToken};
}

type RefreshTokenCheckResult = {
  isValid: boolean;
  providedRefreshToken: string;
};
// MEMO: リフレッシュトークンの有無をチェック
export function checkRefreshTokenExistence(
  refreshToken: string | undefined,
): RefreshTokenCheckResult {
  if (typeof refreshToken !== 'string')
    return {isValid: false, providedRefreshToken: ''};
  return {
    isValid: Boolean(refreshToken),
    providedRefreshToken: refreshToken,
  };
}

export function validateCookie(cookies: Partial<Record<string, string>>): {
  isValid: boolean;
  accessToken: string;
  refreshToken: string;
} {
  const {providedAccessToken} = checkAccessTokenExistence(
    cookies[cookieApiTokenKey],
  );

  const {isValid: isValidRefreshToken, providedRefreshToken} =
    checkRefreshTokenExistence(cookies[cookieApiRefreshTokenKey]);

  return {
    isValid: isValidRefreshToken,
    accessToken: providedAccessToken,
    refreshToken: providedRefreshToken,
  };
}
