import {routes} from '../../../config/constants';
import {QUERY_PARAMS_FOR_REDIRECT_FROM_LOGIN_PAGE} from '../../entities/login/constants';

export const redirectToLoginOnSessionExpiration = async (url: string) => {
  const path = url
    ? `${routes.LOGIN}?${QUERY_PARAMS_FOR_REDIRECT_FROM_LOGIN_PAGE}=${url}`
    : routes.LOGIN;

  location.href = path;
};
