import React, {useState} from 'react';
import {Box, Text} from '@mantine/core';
import {MdClose} from 'react-icons/md';
import {PROFILE_BASE} from '../../../config/constants/routes';
import {useMediaQuery} from '../../../shared/services/useMediaQuery';
import {ScoutModal} from './Container/ScoutModal';

type Props = {
  closePopup: () => void;
};

export const ProfilePromo: React.FC<Props> = ({closePopup}) => {
  const handleProfileEdit = () => {
    location.href = PROFILE_BASE;
  };

  const [isOpenModal, setIsOpenModal] = useState(false);
  const {isPC} = useMediaQuery();
  return (
    <>
      {isPC ? (
        <>
          <Box className="flex flex-row justify-between">
            <Box className="flex flex-row gap-x-4">
              <Box className="text-base text-black-1 flex flex-row">
                「
                <Text
                  className="text-primary-2 cursor-pointer"
                  onClick={handleProfileEdit}
                >
                  プロフィールを編集
                </Text>
                」 から、あなたの志向や経験を充実させると、
                <Text
                  className="text-primary-2 cursor-pointer"
                  onClick={() => {
                    setIsOpenModal(true);
                  }}
                >
                  厳選した優良企業からスカウト
                </Text>
                が届きやすくなります。
              </Box>
            </Box>
            <Box
              className="flex flex-row text-primary-2 items-center text-base cursor-pointer"
              onClick={closePopup}
            >
              <MdClose size={14} />
              <Text>閉じる</Text>
            </Box>
          </Box>
          <ScoutModal
            isOpenModal={isOpenModal}
            setIsOpenModal={setIsOpenModal}
          />
        </>
      ) : (
        <>
          <Box className="flex flex-row justify-between text-sm">
            <Box className="flex flex-row gap-x-4 text-black-1 items-center">
              <Box className="flex">
                <Text
                  className="text-primary-1 cursor-pointer"
                  onClick={handleProfileEdit}
                >
                  プロフィールを入力する
                </Text>
                と、
                <Text
                  className="text-primary-1 cursor-pointer"
                  onClick={() => {
                    setIsOpenModal(true);
                  }}
                >
                  スカウト
                </Text>
                が届きやすくなります。
              </Box>
            </Box>
            <Box
              className="flex flex-row text-primary-1 items-center"
              onClick={closePopup}
            >
              <MdClose size={14} />
            </Box>
            <ScoutModal
              isOpenModal={isOpenModal}
              setIsOpenModal={setIsOpenModal}
            />
          </Box>
        </>
      )}
    </>
  );
};
