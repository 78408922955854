import {parseCookies} from 'nookies';
import {
  cookieApiRefreshTokenKey,
  cookieApiTokenKey,
} from '../../../server/token';

// MEMO: クッキーからアクセストークンを取得
export function extractAccessTokenFromCookie(): string | undefined {
  const cookies = parseCookies();
  return cookies[cookieApiTokenKey];
}

// MEMO: クッキーからリフレッシュトークンを取得
export function extractRefreshTokenFromCookie(): string | undefined {
  const cookies = parseCookies();
  return cookies[cookieApiRefreshTokenKey];
}
